import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import ScheduleModal from "../../common/calendy/ScheduleModal";
import { contactUs } from "../../../redux/action/contact_us/api";
import ReactFlagsSelect from "react-flags-select";
const localhost_key = process.env.REACT_APP_RECHAPTCHA_LOCALHOST_KEY;

const validationSchema = Yup.object().shape({
  contact_name: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .required("Name is required"),
  contact_email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required"),
  contact_phone: Yup.string()
    .min(10, "10 digits required")
    .max(10, "10 digits required")
    .required("Phone number is required"),
  contact_subject: Yup.string().required("Business name is required"),
  contact_msg: Yup.string().required("Annual revenue is required"),
});

const PriceContactUs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const dispatch = useDispatch();
  // const contactState = useSelector((state) => state.contact);
  const [selected, setSelected] = React.useState("GB");
  const recaptchaRef = React.useRef();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const countryCodeMapping = {
      US: "+1",
      GB: "+44",
      AU: "+61",
      CA: "+1",
      JP: "+81",
      IE: "+353",
      ES: "+34",
      FR: "+33",
    };
    setValue("country_code", countryCodeMapping[selected]);
  }, [selected, setValue]);

  const onSubmitWithReCAPTCHA = async (data) => {
    const token = await recaptchaRef.current.executeAsync();

    if (token) {
      await dispatch(contactUs(data));
      setModalIsOpen(true);
    }
  };

  return (
    <div>
      <div className="">
        <div className="lg:z-10 lg:top-32 lg:mx-16">
          <div className="flex flex-col max-w-[1216px] xl:max-w-[1130px] 2xl:max-w-[1250px] mx-auto  lg:flex-row">
            {/* form  */}
            <div className="w-full flex justify-end flex-col">
              <p
                className="block mx-5 mt-5 text-[#173303] font-bold
                           text-[30px] leading-[36px] font-work-sans
                           md:mb-5
                           lg:text-[60px] lg:leading-[74px] "
              >
                Contact Us
              </p>
              <form
                onSubmit={handleSubmit(onSubmitWithReCAPTCHA)}
                className=" m-5 mb-5  border border-[#333333] p-5 
                            rounded-[20px] mx-[16px] bg-white   "
              >
                <div className="flex flex-col  lg:flex-row gap-0 lg:gap-10 ">
                  <div className="lg:w-1/2 w-full mb-1 lg:mb-5 ">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-[16px] font-medium"
                    >
                      Name <span className="text-red-400 inline">*</span>
                    </label>
                    <input
                      type="text"
                      id="name"
                      {...register("contact_name")}
                      className={`shadow-sm border ${
                        errors.contact_name
                          ? "border-red-500"
                          : "border-[#173303]"
                      } text-[16px] rounded-lg block w-full p-2.5`}
                      placeholder=""
                    />
                    <p className="text-red-500">
                      {errors.contact_name?.message}
                    </p>
                  </div>
                  <div className="mb-1 lg:w-1/2 w-full lg:mb-5">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-[16px] font-medium"
                    >
                      Email <span className="text-red-400 inline">*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      {...register("contact_email")}
                      className={`shadow-sm border ${
                        errors.contact_email
                          ? "border-red-500"
                          : "border-[#173303]"
                      } text-[16px] rounded-lg block w-full p-2.5`}
                      placeholder="name@gmail.com"
                    />
                    <p className="text-red-500">
                      {errors.contact_email?.message}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col  lg:flex-row gap-0 lg:gap-10 ">
                  <div className="mb-1 lg:w-1/2 w-full lg:mb-5">
                    <label
                      htmlFor="phone"
                      className="block mb-2 text-[16px] font-medium"
                    >
                      Phone <span className="text-red-400 inline">*</span>
                    </label>
                    <div className="block md:flex">
                      <ReactFlagsSelect
                        selected={selected}
                        onSelect={(code) => setSelected(code)}
                        countries={[
                          "US",
                          "GB",
                          "AU",
                          "CA",
                          "JP",
                          "IE",
                          "ES",
                          "FR",
                        ]}
                        customLabels={{
                          US: "USA (+1)",
                          GB: "UK (+44)",
                          AU: "Australia (+61)",
                          CA: "Canada (+1)",
                          JP: "Japan (+81)",
                          IE: "Ireland (+353)",
                          ES: "Spain (+34)",
                          FR: "France (+33)",
                        }}
                        className="mt-1.5 mr-3 text-[14px] "
                      />
                      <input
                        className=""
                        type="hidden"
                        id="country_code"
                        {...register("country_code")}
                      />
                      <input
                        type="text"
                        id="phone"
                        {...register("contact_phone")}
                        className={`shadow-sm border ${
                          errors.contact_phone
                            ? "border-red-500"
                            : "border-[#173303]"
                        } text-[16px] rounded-lg block w-full p-3 md:px-4`}
                        placeholder="phone number"
                      />
                    </div>
                    <p className="text-red-500">
                      {errors.contact_phone?.message}
                    </p>
                  </div>
                  <div className="mb-1 lg:w-1/2 w-full lg:mb-5">
                    <label
                      htmlFor="businessName"
                      className="block mb-2 text-[16px] font-medium"
                    >
                      Business name{" "}
                      <span className="text-red-400 inline">*</span>
                    </label>
                    <input
                      type="text"
                      id="businessName"
                      {...register("contact_subject")}
                      className={`shadow-sm border ${
                        errors.contact_subject
                          ? "border-red-500"
                          : "border-[#173303]"
                      } text-[16px] rounded-lg block w-full p-2.5`}
                      placeholder=""
                    />
                    <p className="text-red-500">
                      {errors.contact_subject?.message}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col  lg:flex-row  gap-0 lg:gap-10">
                  <div className="mb-1 lg:w-1/2 w-full lg:mb-5">
                    <label
                      htmlFor="annualRevenue"
                      className="block mb-2 text-[16px] font-medium"
                    >
                      Annual revenue{" "}
                      <span className="text-red-400 inline">*</span>
                    </label>
                    <select
                      id="annualRevenue"
                      {...register("contact_msg")}
                      className={`shadow-sm border ${
                        errors.contact_msg
                          ? "border-red-500"
                          : "border-[#173303]"
                      } text-[16px] rounded-lg block w-full p-2.5`}
                    >
                      <option value="">Select your annual revenue</option>
                      <option value="less_than_100K">less than $100K</option>
                      <option value="100K_250K">$100K - $250K</option>
                      <option value="250K_1M">$250K - $1M</option>
                      <option value="1M_5M">$1M - $5M</option>
                      <option value="5M_20M">$5M - $20M</option>
                    </select>
                    <p className="text-red-500">
                      {errors.contact_msg?.message}
                    </p>
                  </div>
                  <div className="mb-1 lg:w-1/2 w-full lg:mb-5">
                    <label
                      htmlFor="annualRevenue"
                      className="block mb-2 text-[16px] font-medium"
                    >
                      How can we help?
                    </label>
                    <textarea
                      id="how_can_we_help"
                      {...register("how_can_we_help")}
                      className={`shadow-sm border ${
                        errors.how_can_we_help
                          ? "border-red-500"
                          : "border-[#173303]"
                      } text-[16px] rounded-lg block w-full `}
                    ></textarea>
                    <p className="text-red-500">
                      {errors.how_can_we_help?.message}
                    </p>
                  </div>
                </div>
 f
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey="6LfKnw4qAAAAAPkQScNPbWawOnpUMi244cp990ld"
                  // sitekey={localhost_key}
                />
                <div className="flex justify-center mt-10">
                  <button
                    type="submit"
                    className="block py-2 px-5 relative group font-bold ease-in-out w-full text-center bg-[#9fe870] border shadow-lg
                     rounded-full text-nowrap hover:text-[#9fe870] hover:bg-[#173303] duration-1000"
                  >
                    SUBMIT
                  </button>
                </div>
                <div className="mt-5">
                  <p className="font-dm-sans text-[16px]">
                    *This information may be transcribed, used, and stored by
                    third parties in accordance with our Privacy Policy.
                  </p>
                </div>
              </form>
              <ScheduleModal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceContactUs;
