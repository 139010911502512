import { LazyLoadImage } from "react-lazy-load-image-component";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import HeaderContainer from "../../common/header-container/HeaderContainer";
import own from "./img/online_ordering_website.webp";
import power from "./img/restaurant_reservation_system.webp";
import accept from "./img/contactless_payment_system.webp";
import delight from "./img/qr_ordering_system_for_restaurants.webp";
import pos from "./img/pos.webp";
import delivery_management from "./img/delivery_management.webp";
import order_management from "./img/order_management.webp";
import online_reservation from "./img/online-reservation.webp";

const GetFood = () => {
  const feature = [
    {
      img: pos,
      title: "AI-Powered POS to Accelerate Efficiency",
      desceription:
        "The new-age POS for your restaurant. Manage billing, inventory, online orders, customer relationships & much more, all from a centralised dashboard.",
      route: "/point-of-sales",
    },
    {
      img: own,
      title: "Branded Website & Mobile App",
      desceription:
        "Your own branded e-shop is built to help you sell more and power up your online presence.",
      route: "/online-ordering-website",
    },
    {
      img: delight,
      title: "Quick and Easy QR Code Dining Solutions",
      desceription:
        "Make ordering effortless for guests, Boost staff efficiency and capacity.",
      route: "/qr-ordering-system-for-restaurants",
    },
    {
      img: power,
      title: "Manage reservations & turn more tables",
      desceription:
        "See all your reservations, waiting lists, and seated guests from one view. Manage reservations in one place. Improve table turn-times. Waitlist management.",
      route: "/restaurant-reservation-system",
    },
    {
      img: accept,
      title: "Seamless In-Store Payments",
      desceription:
        "Accept swipe, dip, tap, or QR code payments. Accept payments quickly, easily & securely. Let shoppers pay their way with multiple payment options.",
      route: "/contactless-dining",
    },
    {
      img: delivery_management,
      title: "Delivery Management",
      desceription:
        "Smartly manage all your deliveries with our seamless 3rd party integrations.",
      route: "/delivery-management",
    },
    {
      img: order_management,
      title: "Online Restaurant Table Booking System",
      desceription:
        "Streamline booking and reservations for restaurants of any size. Remember customer preferences to offer repeat clients personalized experiences that keep them coming back!",
      route: "/online-reservation",
    },
    {
      img: online_reservation,
      title: "Your all-in-one ordering system",
      desceription:
        "Simplify your tablet setup down to a single device. View and manage all online and manual orders in one chronological list.",
      route: "/order-management#Preorder",
    },
  ];
  return (
    <HeaderContainer>
      <div className="mx-5 md:mx-12 lg:mx-14 2xl:mx-0  ">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1000"
          className="mt-[310px] mb-16 flex justify-center flex-col items-center
                     md:mb-1 md:mt-[600px] lg:mt-16 xl:mt-5 "
        >
          <p
            className="text-[30px] leading-[34px] text-[#333333] font-bold text-center capitalize font-work-sans
                       lg:w-[80%]  
                       2xl:text-[44px] 2xl:leading-[53px] 2xl:w-[50%] "
          >
            Build and grow your business with FusionPOS
          </p>
          <p
            className="text-[20px] leading-[24px] text-center mt-5 text-[#333333] font-dm-sans font-medium mb-8 
                       xl:w-[75%]
                       2xl:text-[24px] 2xl:leading-[40px] 2xl:w-[75%]"
          >
            We’re built for restaurants by restaurateurs. Get all the tools you
            need to take your business to the next level & join the 5000+
            merchants using FusionPOS.
          </p>
        </div>
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="800"
          className="grid-col-1 gap-x-10 lg:gap-x-16 
                    md:grid  md:grid-cols-2  "
        >
          {/* image  */}
          {feature.map((item, index) => (
            <div
              className=" my-12 shadow-2xl rounded-xl hover:scale-105 duration-1000
                          md:my-8
                          lg:rounded-xl
                          xl:my-12
                          "
              key={index}
            >
              <div className=" ">
                <LazyLoadImage
                  src={item.img}
                  alt={item.img}
                  width="100%"
                  height="100%"
                  className="border rounded-2xl p-2"
                />
              </div>
              <div
                className="mt-2 mb-2
                         md:mt-6 md:mb-6
                         lg:mt-6 lg:mb-3 "
              >
                <div
                  className="text-[24px] leading-[26px] font-bold text-center text-[#333333] font-work-sans
                              md:text-[18px] md:leading-[24px] h-10 capitalize px-2
                              lg:text-[24px] lg:leading-[28px]"
                >
                  {item.title}
                </div>
              </div>
              <div
                className=" text-[18px] leading-[22px] text-center text-[#333333] font-dm-sans
                            font-medium h-auto md:h-28 px-5
                             lg:text-[20px] lg:leading-[28px] lg:w-[90%] my-5 lg:mx-auto
            "
              >
                <p>{item.desceription}</p>
              </div>
              <div
                className="flex justify-center text-[20px] text-[#9fe870] mb-5 hover:text-[#173303]
                            "
              >
                <Link to={item.route}>
                  <button
                    className="hover:text-[#9fe870] hover:bg-[#173303] text-nowrap shadow-lg
                           bg-[#9fe870] text-[#173303] rounded-[10px] duration-1000 my-4
                            text-[18px] leading-[20px] 
                            font-dm-sans py-[6px] px-[16px] font-bold 
                            lg:text-[20px] lg:leading-[24px] 
                            lg:py-[12px] lg:px-[16px]
                            2xl:py-[12px] 2xl:px-[16px] "
                  >
                    Learn More
                    <MdKeyboardArrowRight className="inline  " size="26px" />
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="800"
          className="mt-10 mb-10 md:mt-6 md:mb-6 flex justify-center flex-col items-center"
        >
          <p
            className=" text-[30px] leading-[34px]  text-[#333333] font-bold text-center font-work-sans
                      lg:w-[80%] capitalize
                      2xl:text-[44px] 2xl:leading-[60px] 2xl:w-[90%]"
          >
            Increase sales, No commission fees. You'll Be In Good Company.
            5,000+ Forward-thinking partners.
          </p>
          <p
            className="text-[20px] leading-[24px] font-medium text-center mt-5 font-dm-sans text-[#333333]
                     2xl:text-[24px] 2xl:leading-[40px] "
          >
            FusionPOS powers your business. Sell smarter. Sell more.
          </p>
        </div>
      </div>
    </HeaderContainer>
  );
};

export default GetFood;
