import React, { useEffect, useState } from "react";

const SiteMap = () => {
  const [sitemap, setSitemap] = useState(null);
  const apiResponse = [
    { url: "/", changefreq: "weekly", priority: 1.0 },
    { url: "/about-us", changefreq: "weekly", priority: 0.8 },
    { url: "/online-ordering-website", changefreq: "weekly", priority: 0.8 },
    { url: "/restaurant-reservation-system", changefreq: "weekly", priority: 0.8 },
    { url: "/qr-ordering-system-for-restaurants", changefreq: "weekly", priority: 0.8 },
    { url: "/contactless-dining", changefreq: "weekly", priority: 0.8 },
    { url: "/point-of-sales", changefreq: "weekly", priority: 0.8 },
    { url: "/delivery-management", changefreq: "weekly", priority: 0.8 },
    { url: "/order-management#Preorder", changefreq: "weekly", priority: 0.8 },
    { url: "/online-reservation", changefreq: "weekly", priority: 0.8 },
    { url: "/pricing", changefreq: "weekly", priority: 0.8 },
    { url: "/blog", changefreq: "weekly", priority: 0.8 },
    { url: "/areas", changefreq: "weekly", priority: 0.8 },
    { url: "/terms-conditions", changefreq: "weekly", priority: 0.8 },
    { url: "/privacy-and-policy", changefreq: "weekly", priority: 0.8 },
    { url: "/contact-us", changefreq: "weekly", priority: 0.8 },
    { url: "/areas/", changefreq: "weekly", priority: 0.8 },
  ];
  const hostname = document.location.hostname;
  useEffect(() => {
    const sitemapContent = `
          <?xml version="1.0" encoding="UTF-8"?>
            <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" 
            xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" 
            xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
            http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
              ${apiResponse
        ?.map(
          (v) =>
            `<url>
                      <loc>${hostname}${v.url}</loc>
                      <lastmod>${v.changefreq}</lastmod>
                      <priority>${v.priority}</priority> 
                    </url>`
        )
        .join("\n")}
          </urlset>`;
    setSitemap(sitemapContent);
  }, [hostname]);

  return (
    <div>
      <pre>{sitemap}</pre>
    </div>
  );
};

export default SiteMap;