import React, { useState } from "react";
import HeaderContainer from "../../common/header-container/HeaderContainer";
import CustomerStories from "./img/customer.webp";
import CustomerReview from "../../common/customer-review/CustomerReview";
import { Link } from "react-router-dom";
import RequestDemo from "../../common/request_demo_form/RequestDemo";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "animate.css";

const ViewCustomerStories = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const callForm = () => {
    setModalIsOpen(true);
  };

  return (
    <div className="">
      <HeaderContainer>
        {/* upper content  */}
        <div className="mt-10 mb-10 md:mx-10 md:mb-6 mx-5">
          <CustomerReview />
          <div className="flex justify-center">
            <a
              href="https://www.youtube.com/watch?v=FOmYSBBfs-A"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                className="p-2 px-[25px] mb-6 bg-[#9fe870] text-[#173303] 
                          hover:bg-[#173303] hover:text-[#9fe870] shadow-lg
                          text-[20px] leading-[30px] duration-1000 rounded-[10px] font-dm-sans
                          md:text-[24px] md:leading-[30px] font-bold  
                          md:p-4 md:px-[35px] md:mb-10 "
              >
                View Customer Stories
              </button>
            </a>
          </div>
          <div className=" flex justify-center">
            <p
              className="text-[30px] leading-[36px] text-[#333333] font-bold text-center font-work-sans capitalize
                       md:text-[44px] md:leading-[53px] md:w-[80%]"
            >
              {" "}
              Your customers appreciate you and seek ways to improve service
              delivery.
            </p>
          </div>
          <div className=" flex justify-center">
            <p
              className="  text-[20px] leading-[24px] font-dm-sans
            md:text-[24px] md:leading-[30px] text-center mt-5 font-medium md:w-[88%]
           2xl:text-[24px] 2xl:leading-[40px] text-[#333333]   "
            >
              Successful restaurants know how to keep their customers returning.
              We provide you with the key ingredient to understand your
              customers and keep them coming back to you.
            </p>
          </div>
        </div>

        {/* lower content  */}
        <div
          className="w-full  mt-10
                        mb-20 md:w-2/3 md:mx-auto md:mt-10  md:mb-20 "
        >
          <div className="grid  lg:grid-cols-2  ">
            {/* content  */}
            <div className=" mx-5 md:mx-0 text-center md:text-start  ">
              <div className="mt-6 md:mt-3">
                <p
                  className="font-work-sans text-[20px] leading-[24px]
                             pr-0 font-bold text-[#333333]
                             md:pr-4
                             lg:w-[85%] 
                              "
                >
                  Your Relationships, Your Information
                </p>
                <p
                  className="text-[16px] font-dm-sans text-[#333333] leading-[26px] mt-5 pr-0 font-semibold
                              md:pr-4  md:leading-[20px] lg:leading-[30px] lg:w-[85%]"
                >
                  Unlike marketplaces, you have full control over your customer
                  data. Grow both your relationships and your database.
                </p>
              </div>

              <div className="mt-6 md:mt-3 xl:mt-6">
                <p
                  className="font-work-sans text-[20px] leading-[24px] pr-0 md:pr-4
                               font-bold text-[#333333] lg:w-[85%]"
                >
                  Turn Transactions Into Connections
                </p>
                <p
                  className="text-[16px] font-dm-sans text-[#333333] leading-[26px] md:leading-[20px] font-semibold
                              lg:w-[85%] lg:leading-[30px] mt-5 pr-0 md:pr-4 "
                >
                  Gain a 360-degree view of your customers' order history, past
                  visits, and interactions to understand their needs before they
                  do.
                </p>
              </div>

              <div className="mt-12 md:mt-6  xl:mt-12">
                <p
                  className="font-work-sans text-[20px] leading-[24px] pr-0 md:pr-4
                               font-bold text-[#333333] lg:w-[85%]"
                >
                  Unlock Sales, Engage Top Customers
                </p>
                <p
                  className="text-[16px] font-dm-sans text-[#333333] leading-[26px] font-semibold
                              md:leading-[20px] lg:w-[85%] lg:leading-[30px] mt-5 pr-0 md:pr-4"
                >
                  Increase sales predictability by understanding and engaging
                  with your most valuable customer segments.
                </p>
              </div>

              <div className="mt-6 md:mt-3 xl:mt-6">
                <p className="font-work-sans text-[20px] leading-[24px] pr-0 md:pr-4 font-bold text-[#333333] lg:w-[70%]">
                  Automate Marketing, Set and Forget
                </p>
                <p
                  className="text-[16px] font-dm-sans text-[#333333] leading-[26px]  font-semibold
                             md:leading-[20px]
                             lg:w-[85%] lg:leading-[30px] mt-5 pr-0 md:pr-4"
                >
                  Boost revenue and encourage repeat business with automated
                  marketing tools.
                </p>
              </div>
            </div>
            {/* image  */}
            <div className="md:mt-16 mt-10 mx-5 md:mx-0 lg:mt-20 xl:mt-2 ">
              <LazyLoadImage
                src={CustomerStories}
                alt="CustomerStories"
                className=" md:p-0  "
              />
              <div className=" flex justify-center ">
                <button
                  onClick={callForm}
                  className="hover:text-[#9fe870] hover:bg-[#173303] shadow-lg
                  bg-[#9fe870] text-[#173303] rounded-[10px] text-[18px] font-dm-sans
                  py-[10px] px-[10px] font-medium duration-1000 mt-10
                  2xl:py-[10px] 2xl:px-[15px] 2xl:font-bold "
                >
                  Request Demo
                </button>
                <RequestDemo
                  isOpen={modalIsOpen}
                  onRequestClose={() => setModalIsOpen(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </HeaderContainer>
    </div>
  );
};

export default ViewCustomerStories;
