import React, { useState } from "react";
import img from "./img/pricing_header_img.jpg";
import HeaderContainer from "../../common/header-container/HeaderContainer";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import RequestDemo from "../../common/request_demo_form/RequestDemo";

const MainHeader = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const pricing = {
    headline: "Easy And Transparent POS Pricing",
    despcription:
      "Enhance your business with top-notch, purpose-built takeaway software.",
  };
  const callForm = () => {
    setModalIsOpen(true);
  };
  return (
    <div>
      <HeaderContainer>
        <div className="mx-[16px] md:mx-12 lg:mx-16 xl:mx-14 2xl:mx-0">
          {/* content  */}
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="800"
            className="mt-20 mb-5 md:mt-24 lg:mt-28 "
          >
            <h1
              className=" text-[30px] leading-[36px] text-[#333333] 
                         text-wrap font-work-sans font-bold text-center 
                         md:text-[40px] md:leading-[44px] capitalize
                         lg:text-[40px] 
                         xl:text-[50px] xl:leading-[74px]
                         "
            >
              {pricing.headline}
            </h1>
            <p
              className="text-[#333333] text-[20px] leading-[24px] my-5 
                           font-dm-sans text-center font-medium md:my-3                   
                           md:w-[90%] md:text-[20px] md:leading-[28px] md:indent-6
                           lg:text-[22px] lg:leading-[40px] 
                           xl:text-[22px] xl:leading-[40px] mx-auto
                           2xl:text-[22px] 2xl:leading-[40px] 2xl:my-4  "
            >
              {pricing.despcription}
            </p>
          </div>

          {/* image Left */}
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="800"
            className="flex mx-auto  "
          >
            <LazyLoadImage
              src={img}
              alt="qr_image"
              className="w-full lg:px-8 xl:px-0"
            />
          </div>
        </div>
      </HeaderContainer>
      <div className="bg-green-100  my-10 ">
        <HeaderContainer>
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="800"
            className="mt-10 mb-10 mx-5
                       md:mt-32 md:mb-10 md:mx-14
                       lg:mx-14 
                       2xl:mx-2"
          >
            <div className="font-work-sans ">
              <p
                className="text-[16px] text-[#173303] font-bold mx-6 p-3 text-center
                           md:text-[30px]  md:w-3/4 md:p-5 md:mx-auto capitalize
                          xl:text-[40px] lg:text-center lg:w-3/4 lg:p-5 "
              >
                Begin for free and start enjoying our features tailored for you
              </p>
              <p
                className="text-[16px]  text-center mb-3
                         lg:text-[24px] font-medium capitalize
                         2xl:text-[24px] 2xl:leading-[40px] text-[#173303]"
              >
                Select the best plan for your business and switch plans as you
                grow.
              </p>
              <div className="flex justify-center pb-5 md:pb-6 lg:pb-12">
                <button
                  onClick={callForm}
                  className="
                p-1 px-[25px] bg-[#9fe870] text-[#173303] text-[18px] shadow-lg duration-1000
                font-bold  rounded-[10px] hover:text-[#9fe870] hover:bg-[#173303] 
                md:text-[20px] md:mt-4 md:p-3
                lg:text-[20px] lg:mt-8 lg:p-3
                "
                >
                  Book a FREE trial
                </button>
                <RequestDemo
                  isOpen={modalIsOpen}
                  onRequestClose={() => setModalIsOpen(false)}
                />
              </div>
            </div>
          </div>
        </HeaderContainer>
      </div>
    </div>
  );
};

export default MainHeader;
