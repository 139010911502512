import React from "react";
import HeaderContainer from "../../common/header-container/HeaderContainer";
import prime_image from "./img/prime_image.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";

const PrimeComponent = () => {
  const data = {
    headline: "Why FusionPOS?",
    title: "User-friendly System, Cloud- based Software & Top-Notch Service ",
    description: `Founded in 2017, Fusion POS is a cloud based OnlineOrdering and point-of-sale (POS) system that takeaways from paying hefty commission for third world.`,
    img: prime_image,
  };

  return (
    <div>
      <HeaderContainer>
        <div
          className="mt-20 mx-[16px] 
                     md:mt-20  md:mx-14
                     lg:mx-16 lg:mt-28
                     xl:mx-12
                     2xl:mx-0  "
        >
          <div
            className="flex flex-col order-1 gap-1
                       lg:flex-row  lg:gap-10  lg:order-2
                       xl:gap-20"
          >
            {/*Left image  */}
            <div
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="800"
              className="w-full lg:w-1/2 "
            >
              <h1
                className="text-[30px] leading-[36px] font-bold text-[#333333]
                           font-work-sans  block text-center mb-5  capitalize
                           md:text-[36px] md:leading-[44px] md:text-start 
                           lg:hidden
                           xl:hidden xl:text-[48px] xl:leading-[58px]  "
              >
                {data.headline}
              </h1>
              <LazyLoadImage src={data.img} alt="poster" className="w-full" />
            </div>

            {/*Right content  */}
            <div
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="800"
              className="w-full order-1 mt-0
                         lg:order-2 lg:w-1/2                       
                        "
            >
              {/* headline  */}
              <div className="">
                <p
                  className="text-[30px] leading-[36px] font-bold text-[#333333]
                             font-work-sans hidden capitalize text-center
                             lg:text-start
                             xl:text-[48px] xl:leading-[58px]                         
                             lg:block  "
                >
                  {data.headline}
                </p>
              </div>

              {/* title1  */}
              <div className="mt-5 lg:mt-2 2xl:mt-5">
                <h2
                  className="text-[24px] leading-[30px] font-semibold text-[#333333] 
                             font-work-sans capitalize                                                       
                             md:text-start 
                             md:text-[28px] md:leading-[36px]
                             lg:text-[28px] lg:leading-[30px] lg:w-[90%]
                             xl:text-[32px] xl:leading-[40px]  
                             2xl:w-[70%] 2xl:my-6
                             "
                >
                  {data.title}
                </h2>
                <p
                  className="text-[#333333] text-[18px] leading-[24px] 
                             mt-3 mb-0 font-dm-sans font-medium text-justify                    
                             md:text-[20px] md:leading-[28px] md:text-start md:mt-3 md:mb-3                                                      
                             lg:text-[20px] lg:leading-[34px] lg:my-3 lg:w-[90%]
                             xl:text-[24px] xl:leading-[40px] 
                             2xl:text-[24px] 2xl:leading-[40px] 2xl:my-5 "
                >
                  {data.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </HeaderContainer>
    </div>
  );
};

export default PrimeComponent;
