import { useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import { RiDoubleQuotesL } from "react-icons/ri";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import Aozoura from "./img/Aozoura Sushi 1.png";
import Coriander from "./img/Coriander lunch 1.png";
import Yatsun from "./img/Yatsun Sean Choi 1.png";
import jayandhakumar from "./img/jayandhakumar.png";
import HeaderContainer from "../../common/header-container/HeaderContainer";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
const reviews = [
  {
    id: 1,
    customerImg: Coriander,
    customerName: "Shabeer Ulhaq",
    restaurant_name: "Coriander Lounge",
    feedbackTitle: "Fantastic Selection",
    feedbackMessage:
      "We have been using the Fusion POS for the past 5 years and we received excellent customer service and their systems are very secured and very easy to use and also their systems are scalable as well and they listen to your problems and then they can amend the systems if needed and also they will always be there for you if you ever need them.",
  },
  {
    id: 2,
    customerImg: Aozoura,
    customerName: "Ramesh Allapu",
    restaurant_name: "Aorora Sushi",
    feedbackTitle: "Fantastic Selection",
    feedbackMessage:
      "I was opening a restaurant and thinking about how to promote my business. At that time one of my friends recommended Fusion POS. So I’m very happy with the service and I’m going to recommend it for everyone for fusion POS service.",
  },
  {
    id: 3,
    customerImg: jayandhakumar,
    customerName: "jayandhakumar",
    restaurant_name: "Davana Thai",
    feedbackTitle: "Fantastic Selection",
    feedbackMessage:
      "We use Fusion POS as an Online and Offline POS system. They have been really good and they keep on updating and also their customer support is one of the best.",
  },

  {
    id: 4,
    customerImg: Yatsun,
    customerName: "Yatsun Sean Choi",
    restaurant_name: "China Court",
    feedbackTitle: "Fantastic Selection",
    feedbackMessage:
      "I have been with Fusion POS for  nearly 5 years now and it’s been a very happy relationship till now with very very good service.",
  },
];

const CustomerReview = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handlePrev = () => {
    if (swiperInstance) {
      swiperInstance.slidePrev();
    }
  };
  const handleNext = () => {
    if (swiperInstance) {
      swiperInstance.slideNext();
    }
  };

  return (
    <HeaderContainer>
      <div
        className="flex gap-8 justify-between items-center 
                     "
      >
        <button
          style={{ boxShadow: "6px 1px 5px black" }}
          className="p-3 hidden  rounded-full  
                     transition-all  
                     bg-[#9FE870] text-[#173303]
                     hover:text-[#9FE870] hover:bg-[#173303] duration-700
                     lg:block lg:mb-16"
          onClick={handlePrev}
        >
          <HiOutlineArrowLeft size={30} />
        </button>

        <Swiper
          slidesPerView="auto"
          effect={"fade"}
          modules={[Pagination, Autoplay]}
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          pagination={{ clickable: true }}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        >
          {reviews.map((review) => (
            <SwiperSlide key={review.id} className="w-full mb-14  -z-50">
              <div
                className="w-full p-5 rounded-[16px] border-2 border-[#9FE870] 
                           flex flex-col 
                           justify-between shadow-xl items-center px-5
                           sm:flex-col-reverse             
                           md:gap-12 lg:px-10
                           lg:flex-row"
              >
                <div className="order-2 sm:order-1">
                  <span>
                    <RiDoubleQuotesL className="text-[40px] xl:text-[70px]" />
                  </span>
                  <p className="text-[18px]  text-[#333333] mt-2 lg:mt-4 font-dm-sans">
                    {review.feedbackMessage.slice(0, 160)}...
                  </p>

                  <div className="my-3 lg:my-6">
                    <p className="text-[18px] font-bold  mb-1 lg:mb-2 text-primary capitalize font-work-sans">
                      {review.customerName}
                    </p>
                    <p className="text-gray capitalize text-[16px] font-semibold ">
                      {review.restaurant_name}
                    </p>
                  </div>
                  <a
                    href="https://www.youtube.com/watch?v=FOmYSBBfs-A"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      className="flex items-center gap-4  font-bold
                                 shadow-xl rounded-[5px] p-2.5 px-5
                                 bg-[#9FE870] text-[#173303]
                                 hover:text-[#9FE870] hover:bg-[#173303] duration-1000 "
                    >
                      <p className="text-[14px] ">Watch Video</p>
                      <FaAngleRight />
                    </button>
                  </a>
                </div>
                <div className="order-1 sm:order-2 w-fit h-fit">
                  <div className="w-[200px] h-[200px] md:w-[300px] lg:w-[250px] md:h-[300px] ">
                    <LazyLoadImage
                      src={review.customerImg}
                      alt={review.customerImg}
                      className="rounded-[20px] md:rounded-[56px] h-full w-full object-cover p-3  md:p-10 "
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <button
          style={{ boxShadow: "-6px 1px 5px black" }}
          className="p-3 hidden  rounded-full  
                     transition-all duration-700
                     bg-[#9FE870] text-[#173303]
                     hover:text-[#9FE870] hover:bg-[#173303] 
                     lg:block lg:mb-16"
          onClick={handleNext}
        >
          <HiOutlineArrowRight size={30} />
        </button>
      </div>
    </HeaderContainer>
  );
};

export default CustomerReview;
