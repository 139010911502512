import React from "react";
import HeaderContainer from "../header-container/HeaderContainer";
import mission from "./img/mission.webp";
import promises from "./img/promises.jpg";

const MissionAndPromises = () => {
  return (
    <div className="mt-20 md:mt-32 xl:mt-40 mb-10">
      <HeaderContainer>
        <div className="flex gap-0 md:gap-10 flex-col lg:flex-row mx-5 lg:mx-12">
          <div className="w-full p-5  shadow-[inset_0_-2px_4px_rgba(0,0,0,0.6)] font-work-sans rounded-lg hover:shadow-2xl
                        lg:w-1/2 lg:p-10 ">
            <img src={mission} alt={mission} className="rounded-lg h-[70%] md:h-[50%] w-full" />
            <h3 className="text-[24px] md:text-[30px] font-bold py-3"> Our Mission</h3>
            <p className="text-[16px] font-dm-sans">
              Here at Fusion POS our aim is to revolutionise the Epos industry.
              Whether our client runs a Take-away, Pub, Restaurant we want to
              make their lives easier by using our system.{" "}
            </p>
          </div> 
          <div className="w-full lg:w-1/2 p-5 mt-10 lg:mt-0 lg:p-10  font-work-sans rounded-lg  shadow-[inset_0_-2px_4px_rgba(0,0,0,0.6)] hover:shadow-2xl">
            <img src={promises} alt={promises} className="rounded-lg h-[70%] md:h-[50%] w-full" />
            <h3 className="text-[24px] md:text-[30px] font-bold py-3" >FusionPOS Promises</h3>
            <p className="text-[16px] font-dm-sans">
              We promise our clients that we will offer them an easy to use Epos
              system that will make their lives 100% easier, if there are any
              issues we have our dedicated support staff who offer professional
              and friendly help and will have you back up and running in no
              time.{" "}
            </p>
          </div>
        </div>
      </HeaderContainer>
    </div>
  );
};

export default MissionAndPromises;
