import React from "react";
import { Helmet } from "react-helmet";
import { getSeoData } from "./utils/getSeoData";

const SeoComponent = ({ pageUrl, businessTitle }) => {
  
  const hostname = window.location.hostname;
  const canonicalUrl = `https://${hostname}/${pageUrl}`;
  const seoData = getSeoData(pageUrl);
  // alert(JSON.stringify(seoData.Meta_Description));
  console.log("Hostname:", hostname);
  console.log("Canonical URL:", canonicalUrl);
  // console.log("SEO Data:", seoData);
  if (!seoData) return null;

  return (
    <>
      <Helmet>
        <style type="text/css">
          {`
            footer {
              display: block;
            }
          `}
        </style>
        <link rel="canonical" href={canonicalUrl} />
        {seoData.Meta_Title ? (
          <title>{seoData.Meta_Title}</title>
        ) : businessTitle ? (
          <title>{businessTitle + " - Privacy Policy - Online Order"}</title>
        ) : null}
      </Helmet>
      <Helmet>
        <meta name="description" content={seoData.Meta_Description} />
        <meta name="keywords" content={seoData.Keywords} />
      </Helmet>
    </>
  );
};

export default SeoComponent;
