import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
// Import Swiper styles
import "swiper/css/pagination";
import "swiper/css/navigation";

// Import images
import fusion_drive from "./img/fusion_drive.webp";
import fusion_partner from "./img/fusion_partner.webp";
import fusion_suite from "./img/fusion_suite.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ClientLogo = () => {
  const client = [fusion_drive, fusion_partner, fusion_suite];

  return (
    <section
      className="client-logo mx-5  2xl:max-w-[1250px] mb-5 md:mx-16 lg:mx-16 xl:mx-36
                        bg-gradient-to-r from-[#173304] to-[#24940B] 
                        pb-24 pt-5 rounded-[20px] 2xl:mx-auto"
    >
      <p className="text-[50px] text-white text-center mb-16 font-bold">
        Our Other Products
      </p>
      <Swiper
        modules={[Autoplay]}
        slidesPerView={2}
        spaceBetween={0}
        loop={true}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        allowTouchMove={false}
        speed={4000}
        className="client-logo-slider"
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          421: {
            slidesPerView: 1,
          },
          576: {
            slidesPerView: 1,
          },
          718: {
            slidesPerView: 1,
          },
          992: {
            slidesPerView: 1,
          },
          1200: {
            slidesPerView: 2,
          },
          1400: {
            slidesPerView: 2,
          },
          1600: {
            slidesPerView: 2,
          },
          1800: {
            slidesPerView: 2,
          },
          2000: {
            slidesPerView: 2,
          },
        }}
      >
        {client.map((image, index) => (
          <SwiperSlide key={index}>
            <div className="logo-box">
              <LazyLoadImage
                src={image}
                alt={`Client logo ${index + 1}`}
                className=""
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default ClientLogo;
