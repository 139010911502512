import React, { useEffect, useState } from "react";
import Navbar from "../components/common/navbar/Navbar";
import Footer from "../components/common/footer/Footer";
import MainHeader from "../components/pricing/main-header/MainHeader";
import PricePlan from "../components/pricing/price-plan/PricePlan";
import CustomerReview from "../components/common/customer-review/CustomerReview";
import FlexibleHardware from "../components/pricing/flexible-hardware/FlexibleHardware";
import PriceContactUs from "../components/pricing/price-contact-us/PriceContactUs";
import PlansDigDeeper from "../components/pricing/plans-dig-deeper/PlansDigDeeper";
import GoToTop from "../components/common/scroll-icon/GoToTop";
import ChatIcon from "../components/common/chat/ChatIcon";
import ScrollToTop from "../components/common/scroll-icon/ScrollToTop";
import SeoComponent from "../SeoComponent";
import RequestDemo from "../components/common/request_demo_form/RequestDemo";

const Pricing = () => {
  const [modalIsOpenAtVeryFirst, setModalIsOpenAtVeryFirst] = useState(false);

  useEffect(() => {
    const form_variable = localStorage.getItem("_grecaptcha");

    if (!form_variable) {
      setModalIsOpenAtVeryFirst(true);
    }
  }, []);
  return (
    <div>
      <SeoComponent pageUrl="pricing" businessTitle="FusionPOS" />
      <GoToTop />
      <Navbar />
      <MainHeader />
      <PricePlan />
      <PlansDigDeeper />
      <FlexibleHardware />
      <CustomerReview />
      <PriceContactUs />
      <Footer />
      <ChatIcon />
      <ScrollToTop />
      <RequestDemo
        isOpen={modalIsOpenAtVeryFirst}
        onRequestClose={() => setModalIsOpenAtVeryFirst(false)}
      />
    </div>
  );
};

export default Pricing;
